import { useThemeQuery } from '@generated/graphql';

const getThemeQueryVariables = () => {
  const { location } = window;
  const searchParams = new URLSearchParams(location.search);

  const hostName = process.env.NODE_ENV === 'development'
    ? searchParams.get('devHostName') ?? 'wijnvoordeel.nl'
    : location.hostname;
  const themeName = searchParams.get('themeName') ?? undefined;

  return {
    hostMatch: hostName,
    name: themeName,
  };
};

const useClientTheme = () => {
  const { data, loading: themeLoading } = useThemeQuery({
    variables: getThemeQueryVariables(),
  });

  return {
    themeData: data?.theme,
    themeLoading,
  };
};

export default useClientTheme;
